@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');


* {
    margin: 0;
    padding: 0;
    font-family: "Poppins",sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    scroll-behavior: smooth;
}